import React, { useEffect, useState } from "react";
const ROOT_API = process.env.REACT_APP_API_URL;

function AdminJobs() {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoadingJobs, setIsLoadingJobs] = useState(true);
  const [data, setData] = useState([]);
  const [dataJobs, setDataJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const data = await fetch(`${ROOT_API}/jobs`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Accept: "application/json; charset=UTF-8",
            Connection: "close",
            "session-id": JSON.parse(localStorage.getItem("session")),
          },
        });
        const dataJSON = await data.json();
        if (dataJSON) {
          setDataJobs(dataJSON.data.jobs);
          setIsLoadingJobs(false);
        }
      } catch (error) {
        setIsLoadingJobs(false);
        setIsError(true);
        setErrorMsg(error.message);
      }
    };

    fetchJobs();
  }, [isLoadingJobs]);

  return (
    <div className="px-4 py-5">
      <div className="row col-12 center">
        <div className="center text-center">
          <h1>Admin Panel</h1>
        </div>
        <hr className="" />
      </div>

      {isLoadingJobs ? (
        <div className="table">
          <table>
            <thead>
              <tr>
                <th>Repair Reference</th>
                <th>Policy No.</th>
                <th>Date Added</th>
                <th>Product Type</th>
                <th>Job Status</th>
                <th>Expected Dispatch Date</th>
                <th>Additonal Info</th>
              </tr>
            </thead>
            <tbody className="placeholder-table">
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="table">
          <table>
            <thead>
              <tr>
                <th>Repair Reference</th>
                <th>Policy No.</th>
                <th>Date Added</th>
                <th>Product Type</th>
                <th>Job Status</th>
                <th>Expected Dispatch Date</th>
                <th>Additonal Info</th>
              </tr>
            </thead>
            <tbody>
              {dataJobs.map((item) => {
                return (
                  <tr>
                    <td>{item["repair-reference"]}</td>
                    <td>{item["policy-no"]}</td>
                    <td>{item["file-date"]}</td>
                    <td>{item["product-type"]}</td>
                    <td>{item["job-status"]}</td>
                    <td>{item["status-date"]}</td>
                    <td>{item["spare-text3"]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {isError && <span className="error">{errorMsg}</span>}
    </div>
  );
}

export default AdminJobs;
