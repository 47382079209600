import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import JohnLewisPortal from "./JohnLewisPortal";

const ROOT_API = process.env.REACT_APP_API_URL;
const STRIPE_API = process.env.REACT_APP_STRIPE_SECRET;

function JobNew(props) {
  const [errorMsg, setErrorMsg] = useState("Loading...");

  const [isLoadingJob, setIsLoading] = useState(false);
  const [data, setData] = useState("");
  var { id, postcode } = useParams();

  const [rating, setRating] = useState();
  const [pop, setPop] = useState();
  const [fault, setFault] = useState();

  const options = {
    // passing the client secret obtained from the server
    clientSecret: STRIPE_API,
  };

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const data = fetch(`${ROOT_API}/jobs/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Accept: "application/json; charset=UTF-8",
            Connection: "close",
            "session-id": JSON.parse(localStorage.getItem("session")),
          },
        });
        const dataJSON = await data.json();
        if (dataJSON["error-code"] === 404) {
          setErrorMsg(dataJSON["error-message"]);
        } else {
          // setIsLoading(false);
          setData(dataJSON.data);
        }
      } catch (error) {
        setIsLoading(false);
        setErrorMsg(error);
      }
    };
    fetchJob();
  }, [id, isLoadingJob]);

  return (
    <>
      {JohnLewisPortal(
        <>
          <div className="px-4 py-5">
            <div className="row col-12 center">
              <div>
                <h1>Track Your Repair</h1>
              </div>
              <hr style={{ marginBottom: "0px" }} />
            </div>
            <div className="container">
              {isLoadingJob ? (
                <div>{errorMsg}</div>
              ) : (
                <Formik
                  initialValues={{
                    repairReference: "",
                    firstName: "",
                    lastName: "",
                    address1: "",
                    address2: "",
                    town: "",
                    county: "",
                    postCode: "",
                    telephone: "",
                    mobile: "",
                    email: "",
                    manufacturer: "",
                    model: "",
                    faultType: "",
                    datePurchase: null,
                  }}
                  validationSchema={Yup.object({
                    address1: Yup.string().required("Required"),
                    address2: Yup.string(),
                    town: Yup.string().required("Required"),
                    county: Yup.string(),
                    postCode: Yup.string()
                      .max(9, "Please enter a valid postcode")
                      .required("A Post Code is Required"),
                    telephone: Yup.string(),
                    mobile: Yup.string().required("Mobile Number Required"),
                    email: Yup.string()
                      .email("Invalid email address")
                      .required("Customer email Required"),
                    fault: Yup.string().required("Required"),
                    faultType: Yup.string()
                      .required("Required")
                      .oneOf([
                        "screen",
                        "soundonly",
                        "wifi",
                        "nosignal",
                        "standby",
                        "dead",
                        "other",
                      ]),
                  })}
                  onSubmit={async (values) => {
                    // if(values.datePurchase > )
                    fetch(`${ROOT_API}/jobs`, {
                      method: "POST",
                      body: JSON.stringify({
                        "repair-reference": values.repairReference
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                        "first-name": values.firstName
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                        "last-name": values.lastName
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                        address1: values.address1
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                        address2: values.address2
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                        town: values.town
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                        county: values.county
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                        "post-code": values.postCode
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                        telephone: values.telephone
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                        mobile: values.mobile
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                        "mail-address": values.email
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                        fault: values.fault,
                        rating: rating,
                        fault: fault,
                        pop: pop,
                      }),
                      headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        Accept: "application/json; charset=UTF-8",
                        Connection: "close",
                        "session-id": JSON.parse(
                          localStorage.getItem("currentUser")
                        )["session-id"],
                      },
                    }).then((result) => {
                      if (result.status === 201) {
                        return result.json().then((json) => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        });
                      } else {
                        setErrorMsg(result.json()["error-message"]);
                      }
                    });
                  }}
                >
                  <Form className="columns pt-3 px-4 mb-2">
                    <div className="form">
                      <section className="form-section">
                        <div className="row mt-5">
                          <h2>Contact Info</h2>

                          <div>
                            <label htmlFor="mobile">
                              Mobile Telephone{" "}
                              <span className="required">*</span>
                            </label>
                            <Field
                              name="mobile"
                              id="mobile"
                              type="tel"
                              placeholder="Required"
                              required
                              enterKeyHint="next"
                            />
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="mobile"
                            />
                          </div>
                          <div>
                            <label htmlFor="telephone">Home Telephone</label>
                            <Field
                              name="telephone"
                              id="telephone"
                              placeholder="Optional"
                              type="tel"
                              enterKeyHint="next"
                            />
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="telephone"
                            />
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="email">
                              Email Address <span className="required">*</span>
                            </label>
                            <Field
                              name="email"
                              id="email"
                              placeholder="Required"
                              type="email"
                              required
                            />
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="email"
                            />
                          </div>
                        </div>
                        <div className="row mt-5">
                          <h2>Address</h2>

                          <div>
                            <label htmlFor="address1">
                              Address 1 <span className="required">*</span>
                            </label>
                            <Field
                              name="address1"
                              id="address1"
                              placeholder="Required"
                              type="text"
                              required
                              enterKeyHint="next"
                            />
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="address1"
                            />
                          </div>
                          <div>
                            <label htmlFor="address2">Address Line 2</label>
                            <Field
                              name="address2"
                              id="address2"
                              placeholder="Optional"
                              type="text"
                              enterKeyHint="next"
                            />
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="address2"
                            />
                          </div>
                          <div>
                            <label htmlFor="town">
                              Town <span className="required">*</span>
                            </label>
                            <Field
                              name="town"
                              id="town"
                              type="text"
                              required
                              placeholder="Required"
                              enterKeyHint="next"
                            />
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="town"
                            />
                          </div>
                          <div className="col-12 col-md-8 ">
                            <label htmlFor="county">County</label>
                            <Field
                              name="county"
                              id="county"
                              type="text"
                              placeholder="Optional"
                              enterKeyHint="next"
                            />
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="county"
                            />
                          </div>
                          <div className="col-12 col-md-4">
                            <label htmlFor="postCode">
                              Post Code <span className="required">*</span>
                            </label>
                            <Field
                              name="postCode"
                              id="postCode"
                              placeholder="Required"
                              type="text"
                              required
                              enterKeyHint="next"
                            />
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="postCode"
                            />
                          </div>
                        </div>
                      </section>
                      <section className="form-section">
                        <div className="row mt-5">
                          <h2>Fault Details</h2>
                          <div>
                            <label htmlFor="faultType">
                              Fault Type <span className="required">*</span>
                            </label>
                            <Field
                              name="faultType"
                              id="faultType"
                              as="select"
                              required
                            >
                              <option defaultValue value="">
                                Select
                              </option>
                              <option value="screen">Screen Damage</option>
                              <option value="soundonly">
                                No Picture (Sound Only)
                              </option>
                              <option value="wifi">WiFi Fault</option>
                              <option value="nosignal">
                                No Signal (Connectivity)
                              </option>
                              <option value="standby">
                                No Picture or Sound (has Standby Light)
                              </option>
                              <option value="dead">
                                Dead (no Standby light)
                              </option>
                              <option value="other">Other</option>
                            </Field>
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="faultType"
                            />
                          </div>
                          <div>
                            <label htmlFor="fault">
                              Fault Reported <span className="required">*</span>
                            </label>
                            <Field
                              name="fault"
                              id="fault"
                              as="textarea"
                              placeholder="Required"
                              required
                            />
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="fault"
                            />
                          </div>

                          <div>
                            <label htmlFor="datePurchase">
                              Date of Purchase:
                            </label>
                            <br />
                            <Field
                              name="datePurchase"
                              id="datePurchase"
                              type="date"
                              required
                              enterkeyhint="next"
                            />
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="datePurchase"
                            />
                          </div>
                          <div>
                            <label htmlFor="fault">
                              Ratings Plate <span className="required">*</span>
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              required
                              onChange={(event) => {
                                const files = event.target.files[0];
                                setRating(files);
                              }}
                            />
                          </div>
                          <div>
                            <label htmlFor="fault">Fault Pictures</label>
                            <input
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={(event) => {
                                const files = event.target.files;
                                setFault(files);
                              }}
                            />
                          </div>
                          <div>
                            <label htmlFor="fault">Proof of Purchase</label>
                            <input
                              type="file"
                              onChange={(event) => {
                                const files = event.target.files[0];
                                setPop(files);
                              }}
                            />
                          </div>
                        </div>
                      </section>
                    </div>

                    <div
                      className="form-section"
                      style={{ marginLeft: "-40px" }}
                    >
                      <button className="form-button mt-3" type="submit">
                        Submit
                      </button>
                      <p>
                        <span className="required">*</span> indicates a required
                        field
                      </p>
                    </div>
                  </Form>
                </Formik>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default JobNew;
