import React, { useEffect, useState } from "react";
import { HashRouter as Router, Link, Route } from "react-router-dom";

import { history } from "./_helpers/history";
import { Role } from "./_helpers/role";
import { authenticationService } from "./_services/authentication.service";
import { PrivateRoute } from "./_components/PrivateRoute";

// Pages
import HomePage from "./pages/Home";
import Login from "./pages/Login";
import RequestForm from "./pages/RequestForm";
import Reset from "./pages/Reset";
import SettingPage from "./pages/Settings";
import CalendarPage from "./pages/Calendar";

// Admin Pages
import AdminUsers from "./pages/admin/Users";
import AdminJobs from "./pages/admin/Jobs";

// Search Pages
import Status from "./pages/search/Search";
import Reference from "./pages/search/Reference";
import Policy from "./pages/search/Policy";

// Field Service Module
import JobScreen from "./pages/field-service/JobScreen";
import Diary from "./pages/field-service/Diary";
import Summary from "./pages/field-service/Summary";
import Arrive from "./pages/field-service/Arrive";
// import DriverMaps from "./pages/field-service/Maps";

// Portal Module
import RepairRequest from "./pages/portal/RepairRequestForm";
import RepairHomePage from "./pages/portal/RepairHomePage";
import Job from "./pages/portal/JobTrack";
import JobNew from "./pages/portal/JobFirstTime";
import JobBook from "./pages/portal/JobBookCall";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./index.css";
import {
  Search,
  BookOpen,
  Calendar,
  File,
  Package,
  Truck,
  Users,
  Archive,
  Settings,
  Plus,
} from "react-feather";

import Logo from "./img/NewLogo.jpg";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentJob] = useState(localStorage.getItem("currentjob"));

  useEffect(() => {
    authenticationService.currentUser.subscribe((x) => {
      setLoggedIn(x && false);
      setIsAdmin(x && localStorage.getItem("role") === Role.Admin);
    });
  });

  function logout() {
    authenticationService.logout();
    history.push("/#/login");
  }

  return (
    <>
      <Router>
        {loggedIn !== false ? (
          <>
            <Route exact path="/" component={Login} />
            <Route path="/login" component={Login} />
            <Route path="/reset" component={Reset} />
            <Route path="/create-request" component={RepairRequest} />
            <Route path="/track/:id/:postcode" component={Job} />
            <Route path="/newjob/:id/:postcode" component={JobNew} />
            <Route path="/book/:id" component={JobBook} />
          </>
        ) : (
          <>
            <nav className="bg-light sidebar">
              <ul className="sidebar-nav">
                <li>
                  <Link to="/" className="nav-link">
                    <img className="img-fluid logo" src={Logo} alt="Logo" />
                  </Link>
                </li>

		{/*<li className="sidebar-item">
                  <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mb-1 text-muted">
                    <span className="nav-text">Office</span>
                  </h6>

                  <Link
                    to="/search"
                    aria-label="Search Jobs"
                    className="nav-link"
                  >
                    <Search className="sidebar-icon" />
                    <span className="nav-text">Search Jobs</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link to="/spares" aria-label="Spares" className="nav-link">
                    <Package className="sidebar-icon" />
                    <span className="nav-text">Spares</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link
                    to="/van"
                    aria-label="Field Van Runs"
                    className="nav-link"
                  >
                    <Truck className="sidebar-icon" />
                    <span className="nav-text">Van Runs</span>
                  </Link>
                </li>*/}

                <li className="sidebar-item">
                  <hr className="" />

                  <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mb-1 text-muted">
                    <span className="nav-text">Amazon Protect</span>
                  </h6>
                  <Link
                    to="/search"
                    aria-label="Search Jobs"
                    className="nav-link"
                  >
                    <Search className="sidebar-icon" />
                    <span className="nav-text">Search Jobs</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link
                    to="/request"
                    aria-label="New Repair"
                    className="nav-link"
                  >
                    <Plus className="sidebar-icon" />
                    {"  "}
                    <span className="nav-text">Create Repair</span>
                  </Link>
                </li>

		{/*<li className="sidebar-item">
                  <hr className="" />

                  <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mb-1 text-muted">
                    <span className="nav-text">Field Service</span>
                  </h6>
                  <Link
                    to="/summary"
                    aria-label="Field Service Daily Summary"
                    className="nav-link"
                  >
                    <BookOpen className="sidebar-icon" />
                    {"  "}
                    <span className="nav-text">Daily Summary</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link
                    to="/diary"
                    aria-label="Field Service Driver Diary"
                    className="nav-link"
                  >
                    <Calendar className="sidebar-icon" />
                    {"  "}
                    <span className="nav-text">Diary</span>
                  </Link>
                  <hr />
                </li>*/}

                <div className="sidebar-item">
                  {isAdmin === true && (
                    <>
                      <h6 className="sidebar-heading justify-content-between align-items-center px-3 mb-1 text-muted">
                        <span className="nav-text">Admin</span>
                      </h6>

                      <li className="sidebar-item">
                        <Link
                          to="/admin/users"
                          aria-label="Users"
                          className="nav-link"
                        >
                          <Users className="sidebar-icon" />
                          {"  "}
                          <span className="nav-text">Users</span>
                        </Link>
                      </li>
                      <li className="sidebar-item">
                        <Link
                          to="/admin/jobs"
                          aria-label="Jobs"
                          className="nav-link"
                        >
                          <Archive className="sidebar-icon" />
                          {"  "}
                          <span className="nav-text">Jobs</span>
                        </Link>
                      </li>
                      {/* <li className="sidebar-item">
                        <Link
                          to="/admin"
                          aria-label="Reports"
                          className="nav-link"
                        >
                          <File className="sidebar-icon" />
                          {"  "}
                          <span className="nav-text">Reports</span>
                        </Link>
                      </li> */}
                      <hr className="" />
                    </>
                  )}
                  <div className="row mb-2">
                    <img
                      src={Logo}
                      alt="User Profile"
                      className="sidebar-avatar"
                    />
                    <div className="col-7 sidebar-user">
                      <p className="sidebar-user-name">Amazon Protect User</p>
                      <p className="sidebar-user-email">amprotect@amazon.com</p>
                    </div>

                    <a
                      className="dropdown-toggle settings-col setting-dropup"
                      data-bs-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-expanded="false"
                    >
                      {" "}
                      <Settings className="sidebar-icon"></Settings>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          to="/settings"
                          aria-label="Settings"
                          className=""
                        />
                      </li>
                      {/* <li>
                        <a className="dropdown-item" href="#/settings">
                          Settings
                        </a>
                      </li> 
                      <li>
                        <hr className="dropdown-divider" />
                      </li> */}
                      <li>
                        <a className="dropdown-item" onClick={logout}>
                          Log Out
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </ul>
            </nav>
            <main>
              {/* Open Routes */}
              {/* Default Routes */}
              <Route exact path="/" component={HomePage} />
              <PrivateRoute
                path="/admin/users"
                roles={[Role.Admin]}
                component={AdminUsers}
              />
              <PrivateRoute
                path="/admin/jobs"
                roles={[Role.Admin]}
                component={AdminJobs}
              />
              <PrivateRoute path="/settings" component={SettingPage} />
              <PrivateRoute path="/calendar" component={CalendarPage} />

              {/* Amazon Specific */}
              <PrivateRoute path="/request" component={RequestForm} />

              {/* Job Search */}
              <PrivateRoute path="/search" component={Status} />
              <PrivateRoute path="/policy/:id" component={Policy} />
              <PrivateRoute path="/reference/:id" component={Reference} />

              {/* Field Service */}
              <Route path="/diary" component={Diary} />
              <Route path="/field/:id" component={JobScreen} />
              <Route path="/arrive/:id" component={Arrive} />
              <Route path="/summary" component={Summary} />
              {/* <Route path="/maps" component={DriverMaps} /> */}
              {/* Portal */}
              <PrivateRoute path="/jobs/:id/:postcode" component={Job} />

              {currentJob &&
                loggedIn &&
                history.push(`/#/arrive/${currentJob}`)}
            </main>
          </>
        )}
      </Router>
    </>
  );
}
export default App;
