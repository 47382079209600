import React from "react";

function warrantyProducts(data, next) {
  return (
    <>
      <div className="portal-container">
        <button
          onClick={() => {
            data.productType = "Camera";
            next();
          }}
          className="col-12 col-sm-2 mb-3"
        >
          Camera
        </button>
        <button
          onClick={() => (data.productType = "TV")}
          className="col-12 col-sm-2 mb-3"
        >
          TV
        </button>
        {/* TODO: Talk to DAVE */}
        <button
          onClick={() => {
            data.productType = "Camcorder";
            next();
          }}
          className="col-12 col-sm-2 mb-3"
        >
          Camcorder
        </button>
      </div>
      <div className="portal-container">
        <button
          onClick={() => {
            data.productType = "Wearable";
            next();
          }}
          className="col-12 col-sm-2 mb-3"
        >
          Wearable
        </button>
        <button
          onClick={() => {
            data.productType = "Audio";
            next();
          }}
          className="col-12 col-sm-2 mb-3"
        >
          Audio
        </button>
        <button
          onClick={() => {
            data.productType = "Other";
            next();
          }}
          className="col-12 col-sm-2 mb-3"
        >
          Other
        </button>
      </div>
    </>
  );
}

function chargeableProducts(data, next) {
  return (
    <>
      <div className="portal-container">
        <button
          onClick={() => {
            data.productType = "Camera";
            next();
          }}
          className="col-12 col-sm-2 mb-3"
        >
          Camera
        </button>
        <button
          onClick={() => {
            data.productType = "TV";
            next();
          }}
          className="col-12 col-sm-2 mb-3"
        >
          TV
        </button>
        <button
          onClick={() => {
            data.productType = "Headphones";
            next();
          }}
          className="col-12 col-sm-2 mb-3"
        >
          Headphones
        </button>
      </div>
      <div className="portal-container">
        <button
          onClick={() => {
            data.productType = "Camcorder";
            next();
          }}
          className="col-12 col-sm-2 mb-3"
        >
          Camcorder
        </button>
        <button
          onClick={() => {
            data.productType = "Audio";
            next();
          }}
          className="col-12 col-sm-2 mb-3"
        >
          Audio
        </button>
        <button
          onClick={() => {
            data.productType = "Wearable";
            next();
          }}
          className="col-12 col-sm-2 mb-3"
        >
          Wearable
        </button>
      </div>
      <div className="portal-container">
        <button
          onClick={() => {
            data.productType = "Other";
            next();
          }}
          className="col-12 col-sm-2 mb-3"
        >
          Other
        </button>
      </div>
    </>
  );
}

function ProductType(props) {
  const { data, next, previous } = props;

  return (
    <>
      {data.warranty
        ? warrantyProducts(data, next)
        : chargeableProducts(data, next)}
      <button className="col-12 col-sm-2 mb-3" onClick={previous}>
        Previous
      </button>
    </>
  );
}

export default ProductType;
