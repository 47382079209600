import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import JohnLewisPortal from "./JohnLewisPortal";

const ROOT_API = process.env.REACT_APP_API_URL;
const STRIPE_API = process.env.REACT_APP_STRIPE_SECRET;

function JobBook(props) {
  const [errorMsg, setErrorMsg] = useState("Loading...");

  const [isLoadingJob, setIsLoading] = useState(true);
  const [data, setData] = useState("");
  var { id, postcode } = useParams();

  const options = {
    // passing the client secret obtained from the server
    clientSecret: STRIPE_API,
  };

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const data = fetch(`${ROOT_API}/jobs/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Accept: "application/json; charset=UTF-8",
            Connection: "close",
            "session-id": JSON.parse(localStorage.getItem("session")),
          },
        });
        const dataJSON = await data.json();
        if (dataJSON["error-code"] === 404) {
          setErrorMsg(dataJSON["error-message"]);
        } else {
          // setIsLoading(false);
          setData(dataJSON.data);
        }
      } catch (error) {
        setIsLoading(false);
        setErrorMsg(error);
      }
    };
    fetchJob();
  }, [id, isLoadingJob]);

  return (
    <>
      {JohnLewisPortal(
        <>
          <div className="px-4 py-5">
            <div className="row col-12 center">
              <div className="center text-center">
                <h1>Engineer Visit Booking </h1>
              </div>
              <hr style={{ marginBottom: "0px" }} />
            </div>
            <div className="container">
              {isLoadingJob ? (
                <div>{errorMsg}</div>
              ) : (
                <Formik
                  initialValues={{
                    repairReference: "",
                    park: "",
                    covid: "",
                  }}
                  validationSchema={Yup.object({
                    covid: Yup.string().required("Required"),
                    park: Yup.string()
                      .required("Required")
                      .oneOf(["street", "drive", "meter", "other"]),
                  })}
                  onSubmit={async (values) => {
                    fetch(`${ROOT_API}/jobs`, {
                      method: "POST",
                      body: JSON.stringify({
                        "repair-reference": values.repairReference
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                        covid: values.covid
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                        parking: values.park
                          .replace(/(\r\n\t|\n|\r|\t)/gm, "")
                          .replace(/(")/gm, "'"),
                      }),
                      headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        Accept: "application/json; charset=UTF-8",
                        Connection: "close",
                        "session-id": JSON.parse(
                          localStorage.getItem("currentUser")
                        )["session-id"],
                      },
                    }).then((result) => {
                      if (result.status === 201) {
                        return result.json().then((json) => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        });
                      } else {
                        setErrorMsg(result.json()["error-message"]);
                      }
                    });
                  }}
                >
                  <Form className="columns pt-3 px-4 mb-2">
                    <section className="col-12 col-sm-11">
                      <div className="date-selector">
                        <ul>
                          <li>
                            <Field
                              type="radio"
                              name="park"
                              id="date"
                              value="other"
                              className="btn-check"
                              required
                              disabled
                            />
                            <label className="form-button" htmlFor="date">
                              Today
                              <br />
                              (dd/mm)
                            </label>
                          </li>
                          <li>
                            <Field
                              type="radio"
                              name="park"
                              id="date"
                              value="other"
                              className="btn-check"
                              required
                              disabled
                            />
                            <label className="form-button" htmlFor="date">
                              Tomorrow
                              <br />
                              (dd/mm)
                            </label>
                          </li>
                          <li>
                            <Field
                              type="radio"
                              name="park"
                              id="date"
                              value="other"
                              className="btn-check"
                              required
                            />
                            <label className="form-button" htmlFor="date">
                              dd/mm
                            </label>
                          </li>
                          <li>
                            <Field
                              type="radio"
                              name="park"
                              id="date"
                              value="other"
                              className="btn-check"
                              required
                            />
                            <label className="form-button" htmlFor="date">
                              dd/mm
                            </label>
                          </li>
                          <li>
                            <Field
                              type="radio"
                              name="park"
                              id="date"
                              value="other"
                              className="btn-check"
                              required
                            />
                            <label className="form-button" htmlFor="date">
                              dd/mm
                            </label>
                          </li>
                          <li>
                            <Field
                              type="radio"
                              name="park"
                              id="date"
                              value="other"
                              className="btn-check"
                              required
                            />
                            <label className="form-button" htmlFor="date">
                              dd/mm
                            </label>
                          </li>
                          <li>
                            <Field
                              type="radio"
                              name="park"
                              id="date"
                              value="other"
                              className="btn-check"
                              required
                            />
                            <label className="form-button" htmlFor="date">
                              dd/mm
                            </label>
                          </li>
                          <li>
                            <Field
                              type="radio"
                              name="park"
                              id="date"
                              value="other"
                              className="btn-check"
                              required
                            />
                            <label className="form-button" htmlFor="date">
                              dd/mm
                            </label>
                          </li>
                          <li>
                            <Field
                              type="radio"
                              name="park"
                              id="date"
                              value="other"
                              className="btn-check"
                              required
                            />
                            <label className="form-button" htmlFor="date">
                              dd/mm
                            </label>
                          </li>
                          <li>
                            <Field
                              type="radio"
                              name="park"
                              id="date"
                              value="other"
                              className="btn-check"
                              required
                            />
                            <label className="form-button" htmlFor="date">
                              dd/mm
                            </label>
                          </li>
                        </ul>
                      </div>
                    </section>
                    <div className="form">
                      <section className="form-section">
                        <div className="row mt-5">
                          <h2>Where can our Engineer(s) park?</h2>
                          <div>
                            <div role="group">
                              <Field
                                type="radio"
                                name="park"
                                id="parkStreet"
                                value="street"
                                className="btn-check"
                                required
                              />
                              <label
                                className="form-button m-2"
                                htmlFor="parkStreet"
                              >
                                Free On-Street
                              </label>
                              <Field
                                type="radio"
                                name="park"
                                id="parkDrive"
                                value="drive"
                                className="btn-check"
                                required
                              />
                              <label
                                className="form-button"
                                htmlFor="parkDrive"
                              >
                                Driveway
                              </label>
                              <Field
                                type="radio"
                                name="park"
                                id="parkMeter"
                                value="meter"
                                className="btn-check"
                                required
                              />
                              <label
                                className="form-button m-2"
                                htmlFor="parkMeter"
                              >
                                Parking Meter
                              </label>
                              <Field
                                type="radio"
                                name="park"
                                id="parkOther"
                                value="other"
                                className="btn-check"
                                required
                              />
                              <label
                                className="form-button"
                                htmlFor="parkOther"
                              >
                                Other
                              </label>
                            </div>
                          </div>
                          <ErrorMessage
                            component="div"
                            className="text-danger"
                            name="park"
                          />
                        </div>
                      </section>
                      <section className="form-section">
                        <div className="row mt-5">
                          <h2>COVID Questionnaire</h2>

                          <div className="col-12">
                            <label htmlFor="covid">
                              Are you or anyone in your household showing
                              symptoms of COVID-19?
                            </label>
                            <div>
                              <div className="form-check" role="group">
                                <label className="form-check">
                                  <Field
                                    type="radio"
                                    name="covid"
                                    value="yes"
                                    className="form-check-input"
                                    required
                                  />
                                  Yes
                                </label>
                                <label className="form-check">
                                  <Field
                                    type="radio"
                                    name="covid"
                                    value="no"
                                    className="form-check-input"
                                    required
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                            <ErrorMessage
                              component="div"
                              className="text-danger"
                              name="covid"
                            />
                          </div>
                        </div>
                      </section>
                    </div>

                    <button className="form-button mt-3 mb-3" type="submit">
                      Submit
                    </button>
                  </Form>
                </Formik>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default JobBook;
