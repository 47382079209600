import React from "react";
import PlacesAutocomplete from "../PlacesAutocomplete";

function PersonalDetails(props) {
  const { data, handleChange, next, previous } = props;

  return (
    <form>
      <fieldset>
        <div className="row col-12 col-md-6 mb-3">
          <label htmlFor="name">First Name:</label>
          <input
            type="text"
            name="firstName"
            required={true}
            value={data.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="row col-12 col-md-6 mb-3">
          <label htmlFor="name">Last Name:</label>
          <input
            type="text"
            name="lastName"
            required={true}
            value={data.lastName}
            onChange={handleChange}
          />
        </div>
        <div className="row col-12 col-md-6 mb-3">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            name="email"
            required={true}
            value={data.email}
            onChange={handleChange}
          />
        </div>
        <div className="row col-12 col-md-6 mb-3">
          <label htmlFor="confirmEmail">Confirm Email:</label>
          <input
            type="email"
            name="confirmEmail"
            value={data.confirmEmail}
            onChange={handleChange}
          />
        </div>

        <div className="row col-12 col-md-6 mb-3">
          <label htmlFor="phoneNo">Contact Telephone:</label>
          <input
            type="tel"
            name="phoneNo"
            value={data.phoneNo}
            onChange={handleChange}
          />
        </div>
      </fieldset>

      <fieldset>
        <legend>Address</legend>
        <PlacesAutocomplete data={data.address} handleChange={handleChange} />
      </fieldset>

      <fieldset>
        <legend>Return Address</legend>
        <PlacesAutocomplete
          data={data.returnAddress}
          handleChange={handleChange}
        />
      </fieldset>

      <button className="col-12 col-sm-2 mb-3" onClick={previous}>
        previous
      </button>
      {data.email !== "" && data.confirmEmail !== "" ? (
        <button className="col-12 col-sm-2 mb-3" onClick={next}>
          Next
        </button>
      ) : (
        <>
          {data.email !== data.confirmEmail && <>email not match</>}
          {(data.firstName === "" || data.lastName === "") && (
            <>Required field missing</>
          )}
        </>
      )}
    </form>
  );
}

export default PersonalDetails;
