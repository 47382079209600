import React from "react";
import "./portal.css";

function NavBar() {
  return (
    <header className="portal-header">
      <div className="portal-logo">
        <img
          height={"70px"}
          src="https://www.johnlewis.com/header-ui-assets/static/images/JL_Pride_Logo_2021-f149a305.png"
          alt="John Lewis Pride Logo"
        ></img>
      </div>
      <nav className="portal-nav">
        <ul className="list">
          <li>
            <a href="https://www.dkavs.co.uk/">Home</a>
          </li>
          <li>
            <a href="https://www.dkavs.co.uk/services/">Services</a>
          </li>
          <li>
            <a href="https://www.dkavs.co.uk/about-us/">About Us</a>
          </li>
          <li>
            <a href="https://www.dkavs.co.uk/contact-us/">Contact Us</a>
          </li>
          <li>
            <a href="https://www.dkavs.co.uk/customer-feedback/">Feedback</a>
          </li>
          <li>
            <a href="https://www.dkavs.co.uk/frequently-asked-questions/">
              FAQ
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

function Footer() {
  return (
    <>
      <footer className="footer-jl">
        {/* Address */}
        <div className="footer-address">
          <h2>Where To Find Us: </h2>
          <aside>
            <p>
              DK Audio Visual Services
              <br />
              Unit 17 Gatwick Metro Centre
              <br />
              Balcombe Road
              <br />
              Horley
              <br />
              Surrey
              <br />
              RH6 9GA
            </p>

            <p>Tel: 01293 406854</p>
          </aside>
        </div>
        <div>
          {/* Social Links */}
          <div className="social-link">
            <ul className="list">
              <li style={{ lineHeight: "10px" }}>
                <a
                  href="https://www.facebook.com/johnlewisretail"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    height={"21px"}
                    width={"21px"}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 9 17"
                  >
                    <path d="M5.842 17V9.246h2.653l.397-3.023h-3.05v-1.93c0-.874.247-1.47 1.526-1.47H9V.118A22.13 22.13 0 006.622 0C4.271 0 2.66 1.408 2.66 3.995v2.228H0v3.023h2.66V17h3.182z"></path>
                  </svg>
                  <span className="u-audible">Facebook</span>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/JohnLewisRetail"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    height={"21px"}
                    width={"21px"}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="3 5 18 15"
                  >
                    <path d="M20.504 5.277a7.282 7.282 0 01-2.347.918A3.641 3.641 0 0015.462 5c-2.038 0-3.692 1.696-3.692 3.786 0 .297.032.586.095.863-3.069-.158-5.79-1.664-7.612-3.958a3.855 3.855 0 00-.5 1.906c0 1.313.652 2.472 1.644 3.152a3.63 3.63 0 01-1.674-.473v.047c0 1.836 1.273 3.366 2.963 3.713a3.608 3.608 0 01-1.668.066c.47 1.504 1.833 2.599 3.45 2.628A7.29 7.29 0 013 18.3 10.282 10.282 0 008.66 20c6.795 0 10.508-5.77 10.508-10.774 0-.164-.003-.329-.01-.49A7.58 7.58 0 0021 6.776a7.2 7.2 0 01-2.12.596 3.778 3.778 0 001.624-2.095"></path>
                  </svg>
                  <span className="u-audible">Twitter</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/johnlewis/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    height={"21px"}
                    width={"21px"}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="3 3 18 18"
                  >
                    <path d="M17.79 7.13c0 .577-.458 1.044-1.022 1.044a1.033 1.033 0 01-1.022-1.044c0-.576.458-1.043 1.022-1.043.564 0 1.022.467 1.022 1.043zm-5.791 7.853c-1.613 0-2.92-1.335-2.92-2.982 0-1.647 1.307-2.982 2.92-2.982s2.92 1.335 2.92 2.982c0 1.647-1.307 2.982-2.92 2.982zm0-7.604c-2.5 0-4.525 2.07-4.525 4.622s2.026 4.622 4.525 4.622c2.5 0 4.525-2.07 4.525-4.622 0-2.553-2.026-4.622-4.525-4.622zm7.39 8.655c0 1.845-1.478 3.354-3.284 3.354H7.896c-1.807 0-3.284-1.509-3.284-3.354V7.965c0-1.845 1.477-3.354 3.284-3.354h8.209c1.806 0 3.284 1.51 3.284 3.354v8.07zM16.256 3H7.745C5.135 3 3 5.18 3 7.845v8.31C3 18.818 5.135 21 7.745 21h8.51C18.866 21 21 18.82 21 16.154V7.845C21 5.18 18.865 3 16.256 3z"></path>
                  </svg>
                  <span className="u-audible">Instagram</span>
                </a>
              </li>
            </ul>
          </div>
          {/* Copyright */}
          <div className="copyright">
            <span className="copyright">© 2022 DKAVS Ltd</span>
            <br />
            <span>
              Designed by <a href="superbase.com">Superbase</a>
            </span>
          </div>
          <div className="privacy">
            <ul className="list">
              <li>
                <a href="https://www.dkavs.co.uk/privacy-policy/">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="https://www.dkavs.co.uk/terms-conditions/">
                  Terms &amp; Conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

function JohnLewisPortal(html) {
  return (
    <>
      <div>
        <NavBar />
        <div
          className="container"
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          {html}
        </div>

        <Footer />
      </div>
    </>
  );
}

export default JohnLewisPortal;
