import React, { useEffect, useState } from "react";
import logoImg from "../img/NewLogo.jpg";

import { authenticationService } from "../_services/authentication.service";

function Login(props) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (authenticationService.currentUserValue) {
      props.history.push("/");
    }
  });

  function handleSubmit(e) {
    e.preventDefault();
    try {
      authenticationService.login(username, password).then((user) => {
        if (user["error-code"]) {
          var route = `/reset?session-id=${user["session-id"]}`;
          console.log(route);
          props.history.push(route);
        } else {
          const { from } = user || { from: { pathname: "/" } };
          props.history.push(from);
        }
      }, error => {
        setIsError(true);
        setErrorMsg("Username or Password is Incorrect");
      });
    } catch (error) {
      setIsError(true);
      setErrorMsg(error);
    }
  }

  return (
    <div className="container center">
      <img className="mb-4" src={logoImg} alt="DKAVS Logo" width="72" />
      <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

      <form className="form-signin" onSubmit={handleSubmit}>
        <div className="form-row">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            name="email"
            className="form-control"
            id="username"
            value={username}
            autoComplete="username"
            required
            aria-required
            enterKeyHint="next"
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />
        </div>

        <div className="form-row">
          <label htmlFor="current-password">Password</label>
          <input
            type="password"
            name="password"
            className="form-control"
            id="current-password"
            value={password}
            autoComplete="current-password"
            required
            aria-required
            enterKeyHint="done"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder=""
          />
        </div>
        <button className="btn btn-primary">Sign In</button>
        <p className="mt-5 mb-3 text-muted">© DKAVS 2022</p>
      </form>
      {isError && <span className="error">{errorMsg}</span>}
    </div>
  );
}

export default Login;
