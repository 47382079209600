import React, { useEffect } from "react";
import RequestForm from "../../_components/RequestForm/RequestForm";
import PortalHTML from "./Portal";

function RepairHomePage() {
  // API key of the google map
  const GOOGLE_MAP_API_KEY = "AIzaSyDd4a4FyYkL9zHbOFACoBKd6RJl81YGEEM";

  // load google map script
  function loadGoogleMapScript(callback) {
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      callback();
    } else {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener("load", callback);
    }
  }

  useEffect(() => {
    loadGoogleMapScript();
  }, []);

  return (
    <>
      {PortalHTML(
        <div className="px-4 py-5">
          <div className="row col-12 center">
            <div className="center text-center">
              <h1>Track Your Job</h1>
            </div>
            <hr className="" />
          </div>
          <div
            className="container"
            style={{
              minHeight: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <RequestForm />
          </div>
        </div>
      )}
    </>
  );
}

export default RepairHomePage;
