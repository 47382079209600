import React, { useState } from "react";
import FaultDetails from "./FaultDetails";
import Manufacturer from "./ManufacturerStep";
import PersonalDetails from "./PersonalDetails";
import ProductDetails from "./ProductDetails";
import ProductType from "./ProductTypeStep";
import Submit from "./RequestFormSubmit";
import WarrantyStep from "./WarrantyStep";

function RequestForm() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    warranty: false,
    productType: "",
    manufacturer: "",
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phoneNo: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      postCode: "",
    },
    returnAddress: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      postCode: "",
    },
    model: "",
    serialNo: "",
    retailer: "",
    dop: "",
    fault: "",
    faultType: "",
    estimateRequired: "",
  });

  function next() {
    setStep(step + 1);
  }
  function previous() {
    setStep(step - 1);
  }

  function handleChange(event) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  }

  switch (step) {
    case 1:
      return <WarrantyStep data={formData} next={next} />;
    case 2:
      return <ProductType data={formData} next={next} previous={previous} />;
    case 3:
      return <Manufacturer data={formData} next={next} previous={previous} />;
    case 4:
      return (
        <PersonalDetails
          data={formData}
          next={next}
          previous={previous}
          handleChange={handleChange}
        />
      );
    case 5:
      return (
        <ProductDetails
          data={formData}
          next={next}
          previous={previous}
          handleChange={handleChange}
        />
      );
    case 6:
      return (
        <FaultDetails
          data={formData}
          next={next}
          previous={previous}
          handleChange={handleChange}
        />
      );
    default:
      return (
        <Submit data={formData} back={previous} handleChange={handleChange} />
      );
  }
}

export default RequestForm;
