import React from "react";

import "./dashboard.css";
import JobForm from "../_components/JobForm";

function RequestForm() {
  return (
    <>
      {/* Help Box Goes Here */}
      <div className="pt-3 px-4 mb-2">
        <h2>Help</h2>
        <details>
          <summary>Can't Submit</summary>
          <ul>
            <li>Check all red outlined boxes are filled out.</li>
            <li>Post Codes need to be valid (less than 9 characters)</li>
            <li>Repair until has to be greater than £100</li>
          </ul>
        </details>
        <details>
          <summary>My Product Type is not listed</summary>
          <ul>
            <li>Please select 'Other' and list it in</li>
          </ul>
        </details>
        <details>
          <summary>My Manufacturer is not listed</summary>
          <ul>
            <li>Please select 'Other' and list it in</li>
          </ul>
        </details>
        <details>
          <summary>What are Royal Mail Post Pack and DPD SwapIT</summary>
          <ul>
            <li>
              A Royal Mail Post Pack is a paid for envelope we send out to a
              customer
            </li>
            <li>
              DPD SwapIT is a Service that sends a protected, insured crate to
              the customer, who places their product inside it
            </li>
          </ul>
        </details>
        <a
          class="btn btn-info"
          href="mailto:AmProtect@dkavs.co.uk,twg.mgmt-group@sitel.com"
        >
          Feedback
        </a>
      </div>
      <JobForm />
    </>
  );
}

export default RequestForm;
