import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PortalHTML from "./Portal";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const ROOT_API = process.env.REACT_APP_API_URL;
const STRIPE_API = process.env.REACT_APP_STRIPE_SECRET;

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements === null) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe || !elements}>
        Pay
      </button>
    </form>
  );
};

const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

function Job(props) {
  const [errorMsg, setErrorMsg] = useState("Loading...");

  const [isLoadingJob, setIsLoading] = useState(true);
  const [data, setData] = useState("");
  var { id, postcode } = useParams();

  const options = {
    // passing the client secret obtained from the server
    clientSecret: STRIPE_API,
  };

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const data = await fetch(`${ROOT_API}/track/${id}/${postcode}`, {
          method: "GET",
          headers: {
            Accept: "application/json; charset=UTF-8",
            Connection: "close",
          },
        });
        var dataJSON = await data.json();
        if (dataJSON["error-code"] === 200) {
          dataJSON = dataJSON.data;
          setData(dataJSON);
          setIsLoading(false);
        } else {
          setErrorMsg(dataJSON["error-message"]);
        }
      } catch (error) {
        console.log(error);
        setErrorMsg(error.message);
        // setIsLoading(false);
      }
    };
    fetchJob();
  }, [id, postcode, isLoadingJob]);

  return (
    <>
      {PortalHTML(
        <>
          <div className="px-4 py-5">
            <div className="row col-12 center">
              <div className="center text-center">
                <h1>Track Your Job</h1>
              </div>
              <hr className="" />
            </div>
            <div className="container">
              {isLoadingJob ? (
                <p>{errorMsg}</p>
              ) : (
                <>
                  <h2 style={{ borderBottom: "solid 1px black" }}>
                    Job Details
                  </h2>
                  <div
                    className="columns"
                    style={{
                      display: "flex",
                      padding: "0 40px",
                      marginLeft: "-40px",
                    }}
                  >
                    <section className="col-12 col-sm-6">
                      <p>
                        <strong>Job Number:</strong> {data["job-no"]}
                        <br />
                        <strong>Post Code:</strong> {data["post-code"]}
                        <br />
                        <strong>Dealer:</strong> {data.dealer}
                      </p>
                    </section>
                    <section className="col-12 col-sm-6">
                      <p>
                        <strong>New Request Date:</strong> {data["run-file"]}
                        <br />
                        <strong>Arrival Date:</strong> {data["date-added"]}
                        <br />
                        <strong>Check In Date:</strong> {data["date-added"]}
                        <br />
                        <strong>Repair Date:</strong> {data["repair-date"]}
                        <br />
                        <strong>Dispatch Date</strong> {data["despatch-date"]}
                      </p>
                      <p>
                        <strong>Manufacturer: </strong> {data["manufacturer"]}{" "}
                        <br />
                        <strong>Model Number: </strong> {data.model} <br />
                        <strong>Serial Number: </strong> {data["serial-no"]}{" "}
                        <br />
                        <strong>Product Type: </strong> {data["product-type"]}
                      </p>
                    </section>
                  </div>
                  <h3>
                    <strong>Job Status:</strong> {data["job-status"]}
                  </h3>
                  <p>
                    <strong>Advised Fault:</strong> {data["fault-details"]}
                    <br />
                    <strong>Estimate Details:</strong> {data["repair-details"]}
                  </p>
                  {(data["job-status"] === "Waiting payment" ||
                    data["job-status"] === "Waiting estimate") && (
                    <>
                      <h2 style={{ borderBottom: "solid 1px black" }}>
                        Pay for your Repair
                      </h2>
                      <p
                        style={{
                          borderBottom: "solid 1px black",
                          marginBottom: "0",
                          width: "10%",
                        }}
                      >
                        <strong>Labour:£</strong> {data["labour-costs"]}
                        <br />
                        <strong>Parts:£</strong> {data["parts-costs"]}
                        <br />
                        <strong>Courier:£</strong> {data["courier-costs"]}
                        <br />
                        <strong>VAT:£</strong> {data.vat}
                        <br />
                      </p>
                      <p>
                        <strong>Total:£</strong> {data["total-costs"]}
                      </p>
                    </>
                  )}

                  {data["job-status"] === "Waiting estimate" && (
                    <div className="mt-2" style={{ textAlign: "center" }}>
                      <button className="btn btn-success">
                        Accept Estimate
                      </button>
                      <button className="btn btn-outline-warning">
                        Refuse Estimate (£25)
                      </button>
                    </div>
                  )}

                  {data["job-status"] === "Waiting payment" && (
                    <div className="container col-4 mt-2">
                      <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm />
                      </Elements>
                    </div>
                  )}

                  <form className="">
                    <h2 style={{ borderBottom: "solid 1px black" }}>
                      Leave a comment
                    </h2>
                    <section>
                      <label htmlFor="fault">Comment: </label>
                      <textarea name="fault" required={true} maxLength={1000} />

                      <button className="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </section>
                    <p>
                      <small>1000 Character Limit</small>
                    </p>
                  </form>

                  <p>
                    <strong>
                      Additional Information: {data["job-status-text"]}
                    </strong>
                  </p>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Job;
