import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const ROOT_API = process.env.REACT_APP_API_URL;

function JobScreen() {
  const [isLoadingJob, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [jobNo, setJobNo] = useState("");
  const [address, setAddress] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [fault, setFault] = useState("");
  const [repairType, setRepairType] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [jobLatLng, setJobLatLng] = useState("");

  //const [data, setData] = useState("");

  var { id } = useParams();

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const data = await fetch(`${ROOT_API}/field/${id}`, {
          method: "GET",
          headers: {
            Accept: "application/json; charset=UTF-8",
            Connection: "close",
            "session-id": JSON.parse(localStorage.getItem("session")),
          },
        });
        var dataJSON = await data.json();
        if (dataJSON["error-code"] === 404) {
          setErrorMsg(dataJSON["error-message"]);
        } else {
          dataJSON = dataJSON.data;
          setJobNo(dataJSON["job-no"]);
          setAddress(dataJSON.address1);
          setMake(dataJSON.make);
          setModel(dataJSON.model);
          setFault(dataJSON.fault);
          setRepairType(dataJSON["service-type"]);
          setCustomerName(dataJSON["customer-name"]);
          setContactNo(dataJSON.mobile);
          setJobLatLng(dataJSON["lat"] + "%2C" + dataJSON["lng"]);
          setIsLoading(false);
        }
      } catch (error) {
        setErrorMsg(error);
      }
    };
    fetchJob();
  }, [id, isLoadingJob]);

  return (
    <div className="px-4 py-5">
      <div className="row col-12 center">
        <div className="center text-center">
          <h1>Job Details</h1>
        </div>
        <hr className="" />
      </div>
      <div>
        <div className="row col-12">
          {isLoadingJob ? (
            <div>{errorMsg}</div>
          ) : (
            <div>
              <h3>Job Details</h3>
              <p>
                <b>Job No: </b>
                {jobNo}
              </p>
              <p>
                <b>Address: </b>
                {address}
              </p>
              <h3>Model</h3>
              <p>
                <b>Make: </b>
                {make}
              </p>
              <p>
                <b>Model: </b>
                {model}
              </p>
              <p>
                <b>Fault: </b>
                {fault}
              </p>
              <p>
                <b>Repair Type: </b>
                {repairType}
              </p>
              <h3>Customer Details</h3>
              <p>
                <b>Customer Name: </b>
                {customerName}
              </p>
              <p>
                <b>Contact No: </b>
                {contactNo}
              </p>

              <a
                className="btn btn-secondary"
                href={`waze://?ll=${jobLatLng}&navigate=yes`}
                target="_blank"
                rel="noreferrer"
              >
                Open Navigation
              </a>
              <Link className="btn btn-secondary" to={`/arrive/${id}`}>
                Arrived At Job
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobScreen;
