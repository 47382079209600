import React, { useEffect, useState } from "react";
import { Search } from "react-feather";
import { useParams } from "react-router-dom";
import JobList from "../../_components/JobList";

const ROOT_API = process.env.REACT_APP_API_URL;

function Job(props) {
  const [errorMsg, setErrorMsg] = useState("Loading ...");
  const [jobNo, setJobNo] = useState("");
  const [repairReference, setRepairReference] = useState("");

  const [isLoadingJob, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  var { id } = useParams();

  function getJob(e) {
    if (jobNo > "") {
      props.history.push(`/policy/${jobNo}`);
    }
  }

  function getRepair() {
    if (repairReference > "") {
      props.history.push(`/reference/${repairReference}`);
    }
  }

  useEffect(() => {
    const fetchPolicy = async () => {
      try {
        const data = await fetch(`${ROOT_API}/policy/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Accept: "application/json; charset=UTF-8",
            Connection: "close",
            "session-id": JSON.parse(localStorage.getItem("session")),
          },
        });
        const dataJSON = await data.json();
        if (dataJSON["error-code"] !== 200) {
          setErrorMsg(dataJSON["error-message"]);
        } else {
          setData(dataJSON.data.jobs);
          setIsLoading(false);
        }
      } catch (error) {
        setErrorMsg(error.message);
      }
    };
    fetchPolicy();
  }, [id, isLoadingJob]);

  return (
    <div className="px-4 py-5">
      <div className="row col-12 center">
        <div className="center text-center">
          <h1>Find A Job</h1>
        </div>
        <hr className="" />
      </div>
      <div>
        <div>
          <h2>Find A Job</h2>
        </div>
        <div className="">
          <form className="form-inline col-12 col-md-3 my-2 my-lg-0">
            <label htmlFor="search-job">Search by Policy Number</label>
            <div className="input-group">
              <input
                id="search-job"
                name="search-job"
                className="form-control mr-sm-2"
                placeholder="By Policy Number"
                value={jobNo}
                required
                onChange={(e) => {
                  setJobNo(e.target.value);
                }}
              />
              <button
                className="btn btn-outline-success my-2 my-sm-0"
                type="submit"
                aria-label="Search"
                aria-labelledby="search-job"
                onClick={getJob}
              >
                <Search />
              </button>
            </div>
          </form>
          <form className="form-inline col-12 col-md-3 my-2 my-lg-0">
            <label htmlFor="search-reference">Search By Repair Reference</label>
            <div className="input-group">
              <input
                id="search-reference"
                name="search-reference"
                className="form-control mr-sm-2"
                placeholder="By Repair Reference"
                value={repairReference}
                required
                onChange={(e) => {
                  setRepairReference(e.target.value);
                }}
              />
              <button
                className="btn btn-outline-success my-2 my-sm-0"
                type="submit"
                aria-label="Search"
                aria-labelledby="search-reference"
                onClick={getRepair}
              >
                <Search />
              </button>
            </div>
          </form>
        </div>

        <div className="row col-12 py-5">
          <h3>Job Details</h3>
          <div className="col-md-8 col-md-offset-4">
            {isLoadingJob ? <div>{errorMsg}</div> : <JobList data={data} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Job;
